@import "../src/assets/css/__global/fonts.css";

:root {
  --color-white: #ffffff;
  --color-white-1: #ebeef2;
  --color-black: #000000;
  --bg-color: #222629;
  --bg-color-1: #14171c;
  --bg-color-2: #303438;
  --bg-color-3: #101111;
  --bg-color-4: #797f87;
  --bg-color-5: #14181e;
  --bg-color-6: #2d3034;
  --bg-color-7: #424242;
  --bg-color-8: #2d2d2d;
  --primary-red: #f43b57;
  --primary-red-light: #ff556f;
  --primary-blue: #2a89fe;
  --secondary-blue: #1264c9;
  --third-blue: #274c7a;
  --secondary-red: #a0112b;
  --third-red: #d4243f;
  --primary-green: #50dc5e;
  --text-color: #ffffff;
  --input-bg-color: #fff;
  --border-color: #ececec;
  --button-color: #f43b57;
  --color-1: #a9adb1;
  --gray-75: rgba(255, 255, 255, 0.75);
  --gray-50: rgba(255, 255, 255, 0.5);
  --gray-1: #4d545e;
}

html {
  -webkit-text-size-adjust: 100%;
}

/* body.dark {
  --box-bg-color: #333;
  --input-bg-color: #777;
  --border-color: #555;
  --button-color: #46b5d1;
} */

body {
  font-family: Barlow;
  font-size: 100%;
  line-height: 1.5;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  color: var(--text-color);
  background-color: var(--bg-color);
  scroll-behavior: smooth;
}

.container {
  max-width: 1440px;
  padding-right: 16px;
  padding-left: 16px;
}

@media screen and (min-width: 768px) {
  .container {
    padding-right: 48px;
    padding-left: 48px;
  }
}

textarea {
  resize: none;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  /* outline: 0.125rem solid rgba(0, 123, 255, 0.25); */
  outline: none !important;
  outline-offset: 0;
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  /* Tutaj możesz ustawić własne style, np. kolor tła i tekstu */
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-white) !important;
  caret-color: var(--color-white) !important;
}

body.using-mouse :focus {
  outline: none !important;
  box-shadow: none !important;
}

img,
svg {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

/**
* Misc
*/

[type="button"],
button {
  color: var(--color-white);
  border: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn {
  font-size: 1.0625rem;
  line-height: 1.275rem;
  font-weight: 800;
  font-style: italic;
  letter-spacing: 0.053125rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
}

.btn-primary {
  position: relative;
  padding: 0.75rem 4.125rem;
  border-color: transparent;
  background-color: transparent;
  box-shadow: 0px 0.25rem 4rem 0px rgba(251, 72, 72, 0.4);
  backdrop-filter: blur(1.875rem);
}

.btn-primary[type="submit"] {
  padding: 12px 37px;
}

.btn-primary::before,
.btn-primary::after {
  content: "";
  position: absolute;
  border-radius: 0.5rem;
  z-index: -1;
}

.btn-primary::before {
  top: 0.1875rem;
  left: -0.25rem;
  width: calc(100% + 0.5rem);
  height: calc(100% + 0.0625rem);
  background-color: var(--secondary-red);
}

.btn-primary::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--button-color);
  transition: 0.3s;
}

.btn-primary:hover {
  border-color: transparent;
  background-color: transparent;
}

.btn-primary:hover::after {
  background-color: var(--primary-red-light);
}

.btn-primary-blue {
  position: relative;
  color: var(--color-white);
  padding: 0.75rem 4.125rem;
  box-shadow: 0px 0.25rem 4rem 0px rgba(33, 122, 255, 0.4);
  backdrop-filter: blur(1.875rem);
}

.btn-primary-blue::before,
.btn-primary-blue::after {
  content: "";
  position: absolute;
  border-radius: 0.5rem;
  z-index: -1;
}

.btn-primary-blue::before {
  top: 0.1875rem;
  left: -0.25rem;
  width: calc(100% + 0.5rem);
  height: calc(100% + 0.0625rem);
  background-color: var(--third-blue);
}

.btn-primary-blue::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-blue);
  transition: 0.3s;
}

.btn-primary-blue:hover {
  background-color: var(--primary-blue);
  color: var(--color-white);
}

.btn-primary-blue:hover::after {
  background-color: var(--primary-blue);
}

.btn-secondary {
  display: flex;
  align-items: center;
  color: var(--color-white);
  padding: 0.75rem 0.9375rem 0.6875rem;
  border-top: 0.125rem solid;
  border-right: 0.25rem solid;
  border-bottom: 0.25rem solid;
  border-left: 0.25rem solid;
  border-color: var(--color-white);
  background-color: transparent;
  /* opacity: 0.1; */
}

a {
  text-decoration: none;
}

.relative {
  position: relative;
}

.btn-secondary:hover {
  background-color: var(--color-white);
  color: black;
  text-decoration: none;
}

.btn-secondary.disabled,
button.btn-secondary:disabled {
  background-color: transparent;
  color: white;
}

.btn-secondary > svg {
  margin-right: 0.625rem;
}

.contentarea {
  min-height: 300px;
}

.form-control {
  background-color: var(--input-bg-color);
}

.no-scroll {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
