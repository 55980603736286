@import "/src/assets/css/style.scss";

.dashboard {
  &.column {
    flex-direction: column;
  }
  &__content--full-width {
    max-width: unset;
  }

  &__packages {
    padding-bottom: rem(45);

    @include media(">=md") {
      padding-bottom: rem(125);
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin-top: 64px;

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      button {
        width: 100%;

        .container {
          margin-top: 0;
        }
      }
    }
  }
}
