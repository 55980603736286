@import "/src/assets/css/style.scss";

.message_wr_admin {
  margin-top: rem(20);
  height: 100%;

  // @include media(">=xl") {
  //   margin-top: 0;
  // }

  .message_content_admin {
    height: 100%;
    max-height: rem(500);
    display: flex;
    flex-direction: column;

    &-list {
      background-color: var(--bg-color-1);
      overflow-y: scroll;
      height: rem(390);
      list-style-type: none;
      padding: rem(12);
      border-top-left-radius: rem(12);
      border-top-right-radius: rem(12);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        padding: rem(8) rem(12);
        width: fit-content;
        border-radius: rem(4);
        text-align: left;
        word-break: break-word;
        overflow-wrap: break-word;

        @include media(">=sm") {
          max-width: 70%;
        }

        @include media(">=xl") {
          max-width: 60%;
        }

        &.message__author {
          // text-align: right;
          margin-left: auto;
          //   line-break: anywhere;
          background-color: var(--primary-blue);
        }

        &.message__caller {
          text-align: left;
          //   line-break: anywhere;
          background-color: var(--bg-color-7);
        }

        &.message-audio {
          background-color: transparent;
          padding: 0;
        }

        &.deleted {
          background-color: transparent;
          border: 1px solid var(--gray-50);
          border-radius: 20px;
          font-style: italic;
          @include font(14);
        }
      }
    }

    .message__form_wrapper {
      border-top: rem(1) solid var(--gray-1);

      .form-field {
        border-radius: 0;
        border-bottom-left-radius: rem(12);
        border-bottom-right-radius: rem(12);
        overflow: visible;
        display: flex;
        min-height: 86px;

        &.audio-field {
          padding: rem(16);
          gap: 16px;
        }

        .icon-wrapper {
          margin-right: 0 !important;
          display: flex;
          align-items: flex-start;
          padding: rem(8) rem(8) 0 rem(8);
        }

        .icon-btn {
          height: fit-content;
          padding: 4px;

          svg {
            color: var(--gray-50);
            transition: color 0.2s ease;
          }

          &:hover {
            svg {
              color: var(--primary-red);
            }
          }
        }

        .recording__wrapper,
        .audio-wrapper {
          display: flex;
          align-items: center;
          gap: 16px;

          &--spinners {
            display: flex;
            gap: 4px;
          }

          .stop {
            background-color: var(--bg-color-2);
            border-radius: 50%;
            padding: 10px;
          }
        }
      }

      .submit-btn-wrapper {
        display: flex;
        justify-content: flex-end;

        .send_button {
          width: 100%;
          max-width: rem(200);
        }
      }
    }
  }

  .cap {
    border-radius: rem(12);
    width: 100%;
    height: 100%;
    min-height: rem(500);
    background-color: var(--bg-color-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: rem(100);
      height: auto;
    }
  }
}
