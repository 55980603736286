@import "/src/assets/css/style.scss";

.plans {
  &__wrapper {
    justify-content: center;

    @include media(">=md") {
      justify-content: center;
      gap: rem(24);
    }

    @import "/src/assets/css/style.scss";

    .card {
      border-radius: rem(14);
      background-color: var(--bg-color-2);
      box-shadow: rem(3) rem(23) rem(48) 0 rgba(0, 0, 0, 0.35);
      margin-bottom: rem(24);
      cursor: pointer;
      transition: box-shadow 100ms ease-out;
      color: var(--text-color);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: rem(2) solid;
        border-radius: rem(14);
        background-color: transparent;
        opacity: 0;
        transition: opacity 100ms ease-out;
      }

      &:hover::after {
        opacity: 1;
      }

      @include media(">=md") {
        flex: 1 1 calc(50% - rem(12));
        max-width: calc(50% - rem(12));
        margin-bottom: 0;
      }

      @include media(">=lg") {
        flex: 1 1 calc(33% - rem(12));
        max-width: calc(33% - rem(12));
      }

      @include media(">=xl") {
        flex: 1 1 calc(25% - rem(24));
        max-width: calc(25% - rem(24));
      }

      &:nth-child(1),
      &:nth-child(2) {
        @include media(">=md") {
          margin-bottom: rem(24);
        }

        @include media(">=lg") {
          margin-bottom: 0;
        }
      }

      &:nth-child(1) {
        &::after {
          border-color: var(--third-red);
        }

        &.is-active {
          box-shadow: 0px 0.25rem 4rem 0px rgba(251, 72, 72, 0.4) !important;
          backdrop-filter: blur(rem(30));

          &::after {
            opacity: 1;
          }
        }
      }

      &:nth-child(2) {
        color: var(--bg-color-1);
        background: linear-gradient(342deg, #fff 0.95%, #e3edf9 96.94%);

        &::after {
          border-color: rgba(255, 165, 0) !important;
        }

        &.is-active {
          box-shadow: 0px rem(4) rem(64) 0px rgba(255, 105, 0, 0.35) !important;
          backdrop-filter: blur(rem(30));

          &::after {
            opacity: 1;
          }
        }
      }

      &:nth-child(3) {
        background: linear-gradient(156deg, #f43b57 9.78%, #1959a8 42.36%);

        &::after {
          border-color: rgba(244, 59, 87) !important;
        }

        &.is-active {
          box-shadow: 0px rem(4) rem(64) 0px rgba(25, 89, 168, 0.35) !important;
          backdrop-filter: blur(rem(30));

          &::after {
            opacity: 1;
          }
        }

        @include media(">=lg") {
          margin-bottom: 0;
        }
      }

      &:nth-child(4) {
        background: var(--bg-color-3);
      }

      &:nth-child(5) {
        background: linear-gradient(
          162.44deg,
          #1959a8 6.42%,
          #005fd3 53.4%,
          #0a2342 99.44%
        );

        &::after {
          border-color: rgba(229, 138, 0, 1);
        }

        &.is-active {
          box-shadow: 0px rem(4) rem(64) 0px rgba(25, 89, 168, 0.35) !important;
          backdrop-filter: blur(rem(30));

          &::after {
            opacity: 1;
          }
        }
      }

      &:nth-child(6) {
        background: linear-gradient(
          166.41deg,
          #bb001d 10.14%,
          #dc2e49 39.53%,
          #bb001d 65.39%,
          #8e2233 106.65%
        );

        &::after {
          border-color: rgb(160, 160, 160);
        }

        &.is-active {
          box-shadow: 0px rem(4) rem(64) 0px rgba(255, 255, 255, 0.35) !important;
          backdrop-filter: blur(rem(30));

          &::after {
            opacity: 1;
          }
        }
      }

      &:last-of-type {
        margin-bottom: 0;

        &::after {
          border-color: rgba(160, 160, 160) !important;
        }

        &.is-active {
          box-shadow: 0px rem(4) rem(64) 0px rgba(255, 255, 255, 0.35) !important;
          backdrop-filter: blur(rem(30));

          &::after {
            opacity: 1;
          }
        }

        @include media(">=lg") {
          margin-top: rem(24);
        }

        @include media(">=xl") {
          margin-top: 0;
          margin-right: 0;
        }
      }

      &-tag {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: rem(134);
        text-align: center;
        padding: rem(2) 0 rem(1);
        border-radius: 0 0 rem(8) rem(8);

        &--blue {
          background-color: var(--primary-blue);
        }

        &--red {
          background-color: var(--third-red);
        }

        & > span {
          @include font(14, 24, 0.82);
          font-weight: 700;
        }
      }

      &-body {
        padding-top: rem(55);
        padding-bottom: rem(36);

        @include media(">=md") {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: rem(54) rem(16) rem(16);
          padding-top: rem(54);
          padding-bottom: rem(16);
        }
      }

      &-header {
        margin-bottom: rem(22);

        @include media(">=md") {
          margin-bottom: rem(23);
        }
      }

      &-content {
        @include media(">=md") {
          margin-bottom: rem(34);
        }
      }

      &-title {
        @include font(24, 22, 0.72);
        font-weight: 900;
        margin-bottom: rem(7);

        @include media(">=md") {
          @include font(28, 32, 0.84);
          margin-bottom: rem(12);
        }
      }

      &-time {
        @include font(18, 22, 0.54);
        font-weight: 700;
        opacity: 0.7;
      }

      &-description {
        @include font(14, 18.2);
        font-weight: 600;
        padding: rem(8) 0;
        border-top: rem(1) solid #fff;
        border-bottom: rem(1) solid #fff;
        margin-bottom: rem(13);

        @include media(">=md") {
          margin-bottom: rem(12);
        }
      }

      &-text,
      &-list__item {
        @include font(14, 20);
      }

      &-text {
        margin-bottom: rem(13);

        @include media(">=md") {
          margin-bottom: rem(12);
        }

        & > strong {
          font-weight: 800;
        }
      }

      &-list {
        & > li {
          margin-bottom: rem(12);
          padding-left: rem(28);

          & > .svg-container {
            top: 50%;
            left: rem(-4);
            transform: translateY(calc(-50% - rem(1)));
          }

          & > span {
            font-weight: 500;
          }
        }
      }

      &-footer {
        margin-top: rem(131);

        @include media(">=md") {
          margin-top: rem(41);
        }

        .price {
          @include font(22, 22, 0.66);
          font-weight: 700;
          margin-right: rem(15);

          &--original {
            @include font(16, 22, 0.48);
            font-weight: 700;
            text-decoration: line-through;
            opacity: 0.48;
          }
        }
      }
    }
  }
}
