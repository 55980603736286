@import "/src/assets/css/style.scss";

.reports__list {
  @include media(">=xl") {
    &__header {
      margin-bottom: rem(13);
    }

    .header {
      &__item {
        opacity: 0.5;

        &:last-of-type {
          display: none;
          margin-left: auto;
          margin-right: rem(86);
        }
      }

      &__text {
        @include font(12, 16, 0.12);
        font-weight: 500;
        min-width: rem(148);
        margin-right: rem(36);

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  @include media(">=xxl") {
    .header__item:last-of-type {
      display: block;
    }
  }

  &__wrapper {
    @include media(">=xxl") {
      padding-right: rem(3);
    }

    .item {
      padding: rem(25) rem(22) rem(22);
      border-radius: rem(14);
      box-shadow: rem(3) rem(23) rem(48) 0px rgba(0, 0, 0, 0.35);
      background-color: var(--bg-color-2);
      margin-bottom: rem(16);

      &:last-of-type {
        margin-bottom: 0;
      }

      @include media(">=xxl") {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: rem(24);
        padding-right: rem(24);
      }

      &__col {
        &--left {
          @include font(14, 20);
          font-weight: 500;
          padding-bottom: rem(16);
          margin-bottom: rem(17);

          @include media(">=md") {
            @include font(16, 24);
          }

          @include media(">=xxl") {
            padding-bottom: 0;
            margin-bottom: 0;
          }

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            min-height: rem(2);
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              #ffffff 50%,
              rgba(255, 255, 255, 0) 100%
            );
            opacity: 0.16;

            @include media(">=xxl") {
              content: none;
            }
          }

          & > time {
            font-weight: 800;
            margin-bottom: rem(2);

            @include media(">=md") {
              @include font(16, 24, 0.16);
            }
          }

          @include media(">=xl") {
            display: flex;
            align-items: center;

            & > * {
              min-width: rem(148);
              margin-right: rem(36);

              &:first-child {
                margin-right: rem(14);
              }
            }
          }
        }

        &--right {
          @include font(12, 16, 0.12);
          opacity: 0.75;

          @include media(">=xxl") {
            position: relative;
            padding-left: rem(33);

            &::before {
              content: "";
              position: absolute;
              top: rem(-2.5);
              left: 0;
              min-width: rem(2);
              height: calc(100% + rem(4));
              background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 50%,
                rgba(255, 255, 255, 0) 100%
              );
              opacity: 0.16;
            }
          }

          & > button {
            font-weight: 500;

            @include media(">=xxl") {
              position: relative;
              top: rem(-1);
              margin-left: rem(16);

              &:first-of-type {
                margin-left: 0;
              }
            }

            & > span {
              margin-right: rem(5);
            }

            & > svg {
              position: relative;
              top: rem(-2);

              @include media(">=xxl") {
                top: 0;
              }
            }
          }
        }
      }

      &-title {
        @include font(14, 20);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: rem(2);

        @include media(">=md") {
          @include font(16, 24);
          width: rem(304);
        }
      }
    }
  }
}
