@import "/src/assets/css/style.scss";

.error-text {
  color: var(--primary-red);
}

.form {
  .form-field {
    padding: 0;

    svg {
      fill: rebeccapurple !important;
    }

    .form-button {
      flex: 1;
      padding: rem(12) rem(9);
      border-radius: rem(8);
      transition: background-color 0.2s ease-in-out;

      &--active {
        background-color: var(--primary-red);
        box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.4);
        backdrop-filter: blur(rem(30));
      }
    }
  }

  &-label {
    @include font(14, 20, 0.56);
    display: block;
    font-weight: 800;
    font-style: italic;
    text-transform: uppercase;
    margin-bottom: rem(11);

    &__wrapper.has-icon {
      display: flex;
      align-items: center;
      margin-bottom: rem(9);

      .svg-container {
        position: relative;
        top: rem(-3);
        max-width: rem(20);
        margin-right: rem(4);
      }

      & > .form-label {
        margin-bottom: 0;
      }
    }
  }

  &-field {
    position: relative;
    border-radius: rem(8);
    background-color: var(--bg-color-1);
    overflow: hidden;
    width: 100%;

    .form-control {
      background-color: transparent !important;

      &:active {
        background-color: transparent !important;
      }

      &:focus {
        background-color: transparent;
        color: var(--color-white);
      }
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      &.right {
        margin-left: 12px !important;
      }

      &.left {
        margin-right: 12px !important;
      }
    }

    textarea {
      min-height: auto;
    }

    & > svg {
      position: absolute;
      top: 50%;
      right: rem(16);
      transform: translateY(-50%);
    }

    & + .form-field__dsc {
      @include font(14, 20);
      margin: rem(13) 0 rem(11);
      opacity: 0.75;
      color: var(--text-color);
    }
  }

  &-field,
  &-control::placeholder {
    @include font(16, 16);
    color: var(--color-1);
  }

  &-control {
    color: var(--color-white);
    height: auto;
    padding: rem(12) rem(16);
    min-height: 30px;
    border: none;
    background-color: transparent;

    &[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
