@import "/src/assets/css/style.scss";

.form-group-training {
  .label {
    font-style: italic;
    font-weight: 800 !important;
    font-size: rem(14) !important;
    padding-left: 0 !important;
  }

  &:not(.form-group--half) {
    &[role="group"] {
      margin-bottom: rem(36);

      & > label {
        @include font(16, 24);
        font-weight: 500;
        padding-left: rem(11);
        margin-bottom: rem(16);
      }

      & > div {
        padding: rem(7) rem(16) rem(9);
        margin-right: rem(9);
        border-radius: rem(24);
        background-color: var(--bg-color-2);

        &:last-of-type {
          margin-right: 0;
        }
      }

      input[type="radio"] {
        display: none;

        & + label {
          @include font(14, 20);
          font-weight: 500;
          position: relative;
          padding-left: rem(32);
          margin-bottom: 0;
          cursor: pointer;

          &::before,
          &::after {
            content: "";
            position: absolute;
            border-radius: 50%;
          }

          &::before {
            top: calc(50% - rem(12));
            left: 0;
            min-width: rem(24);
            min-height: rem(24);
            background-color: var(--bg-color-1);
          }

          &::after {
            top: calc(50% - rem(7));
            left: rem(5);
            min-width: rem(14);
            min-height: rem(14);
            border-radius: 50%;
            background-color: var(--primary-red);
            opacity: 0;
            transition: opacity 0.2s ease-out;
          }
        }

        &:checked {
          & + label {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    .form-field {
      padding: rem(12) rem(16);
    }
  }

  &:first-of-type {
    padding-right: rem(5) !important;
  }
  &:last-of-type {
    padding-left: rem(5) !important;
  }
}

.form-register {
  &__background {
    position: absolute;
    bottom: rem(-28);
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__container {
    padding: 0 rem(17) 0 rem(16);

    @media screen and (min-width: 1024px) {
      padding: 0 rem(48);
    }
  }

  &__steps {
    margin-bottom: rem(48);
    margin-top: rem(36);

    @include media(">=md") {
      margin-bottom: rem(108);
    }
  }

  &__step {
    position: relative;
    width: 100%;
    max-width: rem(36);
    margin-right: rem(41);
    color: var(--color-white);
    transition: color 0.2s ease-in-out;

    @include media(">=md") {
      max-width: rem(68);
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:not(:last-of-type)::after {
      content: "";
      position: absolute;
      top: rem(19);
      right: rem(-32);
      min-width: rem(24);
      min-height: rem(4);
      border-radius: rem(4);
      background-color: var(--color-white);

      @include media(">=md") {
        right: rem(-35);
      }
    }

    &.active {
      color: var(--primary-red);

      .form-register__step-number,
      .form-register__step-text {
        opacity: 1;
      }
    }

    &.completed {
      .form-register__step-text {
        opacity: 0.5;
      }
    }

    &-number,
    &-text {
      opacity: 0.6;
    }

    &-number {
      margin: 0 0 rem(8);

      & > span {
        color: var(--color-white);
        @include font(15, 16, 0.45);
        font-weight: 900;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - rem(2)));

        @include media(">=md") {
          @include font(18, 16, 0.54);
          transform: translate(-50%, calc(-50% - rem(3)));
        }
      }
    }

    &-text {
      @include font(12, 16, 0.12);
      font-weight: 500;

      @include media(">=md") {
        @include font(13, 16, 0.13);
      }
    }
  }

  .form-final-paragraph {
    @include font(16, 24, 0.01);
    padding-bottom: rem(36);
    max-width: rem(651);
    text-align: center;
    margin: 0 auto;

    span {
      font-weight: 600;
    }

    @include media(">=md") {
    }
  }

  .wr-buttons-final-step {
    margin-bottom: 60px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      text-decoration: none;
    }

    .btn-final-primary {
      margin-bottom: rem(24);
    }

    button {
      max-width: rem(224);
      width: 100%;
      text-align: center !important;
      padding: rem(12) rem(24);
    }

    @include media(">=lg") {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: rem(24);

      .btn-final-primary {
        margin-bottom: 0;
      }
    }
  }

  .form {
    &-inner {
      &[data-step="1"],
      &[data-step="4"] {
        @include media(">=xs") {
          max-width: rem(435);
          margin: 0 auto;
        }

        .form-group {
          @include media(">=md") {
            padding: 0 rem(59);
          }
        }
      }

      &[data-step="2"],
      &[data-step="3"] {
        .form-heading {
          margin-bottom: rem(35);

          @include media(">=md") {
            margin-bottom: rem(65);
          }
        }
      }

      &[data-step="3"] {
        .form-buttons {
          @include media(">=md") {
            margin-top: rem(64);
          }
        }
      }

      &[data-step="4"] {
        .form-heading {
          margin-bottom: rem(36);

          @include media(">=md") {
            margin-bottom: rem(65);
          }
        }
      }

      &[data-step="5"] {
        .form-heading {
          @include font(24, 22);

          @include media(">=md") {
            margin-bottom: rem(65);
            @include font(56, 58);
          }
        }
      }
    }

    &-heading {
      @include font(24, 22, 0.72);
      font-weight: 900;
      font-style: italic;
      margin-bottom: rem(42);

      @include media(">=md") {
        @include font(28, 32, 0.84);
        margin-bottom: rem(33);
      }

      & > span {
        display: inline-block;
        color: var(--color-white);
        -webkit-text-fill-color: var(--bg-color);
        -webkit-text-stroke-width: rem(1.5);
        -webkit-text-stroke-color: var(--color-white);
      }
    }

    &-group {
      margin-bottom: rem(25);

      &-row {
        .form-field {
          padding: rem(6);

          .form-button {
            flex: 1;
            padding: rem(12) rem(9);
            border-radius: rem(8);
            transition: background-color 0.2s ease-in-out;

            &--active {
              background-color: var(--primary-red);
              box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.4) !important;
              backdrop-filter: blur(rem(30));
            }
          }
        }
      }
    }

    &-label {
      @include font(14, 20, 0.56);
      display: block;
      font-weight: 800;
      font-style: italic;
      text-transform: uppercase;
      margin-bottom: rem(11);
    }

    &-field {
      position: relative;
      padding: rem(12) rem(16);
      border-radius: rem(8);
      background-color: var(--bg-color-1);
      overflow: hidden;

      & > svg {
        position: absolute;
        top: 50%;
        right: rem(16);
        transform: translateY(-50%);
      }
    }

    &-field,
    &-control::placeholder {
      @include font(16, 16);
      color: var(--color-1);
    }

    &-control {
      color: var(--color-white);
      min-height: 30px;
      padding: 0;
      border: none;
      background-color: transparent;

      &[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    &-button {
      @include font(16, 16, 0.16);
      font-weight: 600;
    }

    &-buttons {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin-top: rem(36);

      .mediPay-btn {
        width: 100%;
        margin-top: rem(14);
        margin-bottom: rem(14);
      }

      & > .btn-primary {
        margin-bottom: rem(14);

        &[type="submit"] {
          width: 100%;
        }
      }
    }

    &-group {
      &--row {
        .form-group {
          &:first-of-type {
            padding-right: rem(5) !important;
          }
          &:last-of-type {
            padding-left: rem(5) !important;
          }
        }
      }
    }
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(rem(30));
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}
