@import "/src/assets/css/style.scss";

.list {
  display: flex;
  flex-direction: column;
  font-weight: 600;

  .relative {
    position: relative;
  }

  &_header {
    display: none;
    margin-bottom: rem(13);
    padding: 0 rem(22);
    @include media(">=xxl") {
      display: grid;
      column-gap: rem(10);
      grid-template-columns: 1fr 1fr 1fr 1fr rem(100);
    }

    &__item {
      @include font(12, 16, 0.12);
      font-weight: 500;
      min-width: rem(148);
      margin-right: rem(36);
      color: var(--gray-50);
      white-space: nowrap;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    .empty-list {
      padding-top: rem(32);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_item {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;

    padding: rem(25) rem(22) rem(22);
    border-radius: rem(14);
    box-shadow: rem(3) rem(23) rem(48) 0px rgba(0, 0, 0, 0.35);
    background-color: var(--bg-color-2);
    margin-bottom: rem(16);

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media(">=xxl") {
      grid-template-columns: 1fr 1fr 1fr 1fr rem(100);
      column-gap: rem(10);
    }

    .package {
      @include font(16, 18);
      font-weight: 800;
    }

    .super-charged {
      background: linear-gradient(96deg, #f30a2e -3.21%, #4a88ff 107.31%);
      -webkit-background-clip: text;
      color: transparent;
      text-fill-color: transparent; // Dla przeglądarek, które nie obsługują powyższej właściwości
    }

    .xtreme {
      background: black;
      -webkit-background-clip: text;
      color: transparent;
      text-fill-color: transparent; // Dla przeglądarek, które nie obsługują powyższej właściwości
    }

    .item_title {
      text-overflow: ellipsis;
      max-width: rem(200);
      overflow: hidden;

      &--expired {
        color: var(--primary-red);
      }
    }

    .info__mobile {
      display: block;
      font-weight: 300;
      margin-right: rem(6);
      @include font(14, 16);
      @include media(">=xxl") {
        display: none;
      }
    }

    &__details {
      position: relative;
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      @include font(12, 16, 0.12);
      margin-top: rem(12);
      padding-top: rem(12);

      @include media(">=xxl") {
        margin-top: 0;
        padding-left: rem(20);
        padding-top: 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: rem(2);
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 50%,
          rgba(255, 255, 255, 0) 100%
        );
        opacity: 0.16;

        @include media(">=xxl") {
          content: none;
        }
      }

      &::before {
        position: absolute;
        top: rem(-2.5);
        left: 0;
        min-width: rem(2);
        height: calc(100% + rem(4));
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 50%,
          rgba(255, 255, 255, 0) 100%
        );
        opacity: 0.16;

        @include media(">=xxl") {
          content: "";
        }
      }

      a {
        display: flex;
        align-items: center;
        color: var(--gray-75);
        justify-content: center;

        svg {
          margin-left: rem(4);
          margin-top: rem(3);
        }
      }
    }
  }
}
