@import "/src/assets/css/style.scss";

.input_search {
  background-color: var(--bg-color-1);
  padding: rem(12) rem(16);
  border-radius: rem(8);
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include media(">=xl") {
    max-width: rem(400);
  }

  input {
    background-color: var(--bg-color-1);
    outline: none;
    border: none;
    color: white;
    width: 100%;
  }
}
