@import "/src/assets/css/style.scss";

.thank-you {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }

  &__wrapper {
    font-weight: 500;
    color: var(--color-white);
    padding: rem(153) 0;
  }

  .heading[data_set="h1"] {
    @include font(24, 22, 0.72);

    @include media(">=sm") {
      @include font(56, 56, 1.68);
    }
  }

  .form-final-paragraph {
    max-width: rem(651);
    margin-right: auto;
    margin-left: auto;

    @include media(">=sm") {
      opacity: 0.8;
    }

    & > strong {
      @include font(16, 24, 0.16);
      font-weight: 800;
    }
  }

  .wr-buttons-final-step {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media(">=sm") {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      gap: rem(20);
    }

    & > a:first-of-type {
      margin-bottom: rem(21);

      @include media(">=sm") {
        margin-bottom: 0;
      }
    }
  }
}
