@import "/src/assets/css/style.scss";

.message_wr {
  margin-top: 0 !important;
  padding-bottom: rem(42);

  .message_content {
    background-color: var(--bg-color-1);
    overflow-y: scroll;
    list-style-type: none;
    height: rem(500);
    padding: rem(12);
    border-top-left-radius: rem(12);
    border-top-right-radius: rem(12);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li {
      padding: rem(8) rem(12);
      border-radius: rem(4);
      width: fit-content;
      word-break: break-word;
      overflow-wrap: break-word;

      @include media(">=sm") {
        max-width: 70%;
      }

      @include media(">=xl") {
        max-width: 60%;
      }

      &.message__author {
        margin-left: auto;
        background-color: var(--primary-blue);
      }

      &.message__caller {
        text-align: left;
        background-color: var(--bg-color-7);
      }

      &.message-audio {
        background-color: transparent;
        padding: 0;
      }

      &.deleted {
        background-color: transparent;
        border: 1px solid var(--gray-50);
        border-radius: 20px;
        font-style: italic;
        @include font(14);
      }
    }
  }

  .message__form_wrapper {
    border-top: rem(1) solid var(--gray-1);

    .form-field {
      border-radius: 0;
      border-bottom-left-radius: rem(12);
      border-bottom-right-radius: rem(12);
    }

    .submit-btn-wrapper {
      display: flex;
      justify-content: flex-end;

      .send_button {
        width: 100%;
        max-width: rem(200);
      }
    }
  }

  textarea {
    @include font(16, 18);
  }

  button {
    width: 100%;

    @include media(">=lg") {
      width: rem(250);
    }
  }
}

.upgrade-plan {
  @include media(">=xl") {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--bg-color);
      opacity: 0.5;
    }
  }

  &__msg {
    max-width: rem(602);

    @include media(">=xl") {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% - rem(50)));
      z-index: 2;
    }

    & > p {
      @include font(18, 22, 0.54);
      text-align: center;

      @include media(">=sm") {
        @include font(24, 28, 0.72);
      }

      & > strong {
        color: var(--primary-red);
        text-transform: uppercase;

        & > a {
          color: var(--primary-red);
          text-decoration: underline;
        }
      }
    }
  }
}
