@import "/src/assets/css/style.scss";

.dashboard__account {
  .history-wrapper {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    padding: rem(16) 0;
    border-top: 1px solid #595c5e;

    @include media(">=md") {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @include media(">=lg") {
      flex-direction: column;
    }

    @include media(">=xl") {
      flex-direction: row;
    }

    &:last-child {
      border-bottom: 1px solid #595c5e;
    }

    &__package-data {
      display: flex;
      justify-content: center;
      gap: rem(24);

      @include media(">=md") {
        width: 50%;
        display: block;
      }

      @include media(">=lg") {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      @include media(">=xl") {
        width: 50%;
        display: block;
      }

      .title,
      .date {
        text-align: center;
      }

      .title {
        @include font(18, 24, 0.54);
        font-weight: 800;
      }

      .date {
        font-weight: 500;
        color: #bdbebf;
      }

      @include media(">=md") {
        .title,
        .date {
          width: 50%;
          display: inline-block;
          text-align: left;
        }
      }

      @include media(">=lg") {
        .title,
        .date {
          width: auto;
        }
      }

      @include media(">=xl") {
        .title,
        .date {
          width: 50%;
          display: inline-block;
          text-align: left;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      gap: rem(16);
      align-items: center;

      .btn {
        @include font(13, 15.6, 0.78);
        font-weight: 700;
        padding: rem(10) rem(40);

        &-secondary {
          padding: rem(10) rem(16);
          border-color: rgba(255, 255, 255, 0.35);
        }
      }
    }
  }
}
